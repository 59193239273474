import { graphql } from 'gatsby'
import React from 'react'

import { Box, Flex } from '@rebass/emotion'

import { CurveBox, HeroHeadline, Paragraph } from '../components/atoms'
import { SolutionsMarketplacesSVG } from '../components/atoms/SolutionsMarketplacesSVG'
import { SVGDropper } from '../components/atoms/SVGDropper'
import { Layout } from '../components/layouts/Layout'
import {
  ConstrainedWidthContainer,
  ProductSectionItem,
  ProductsGetInTouch,
} from '../components/molecules'
import { GetInTouch, SEO } from '../components/organisms'
import HeroSVG from '../images/lottie/Marketplaces_Graphic_Hero.json'
import MarketplacesImageMobile from '../images/Marketplaces_Graphic_Body_Mobile.svg'
import { Mq } from '../styles/settings/theme'
import styled from '../styles/styled'

const HeroImageContainer = styled(Box)`
  height: 15rem;
  order: -1;

  ${Mq.sm} {
    min-height: 30rem;
    order: 0;
  }
`

const VerticalSectionImageMobile = styled.img`
  margin: 0 0 2rem 0;
  width: 100%;

  ${Mq.md} {
    display: none;
  }
`

const ColoredBackgroundContainer = styled(Box)`
  background-color: ${props => props.theme.colors.lightBlue};
  position: relative;
`

const VerticalImageContainer = styled(Box)`
  display: none;

  ${Mq.md} {
    display: block;
  }
`

const CurveBoxBottom = styled(CurveBox)`
  height: 50px;
  bottom: 0;
`

const CurveBoxTop = styled(CurveBox)`
  height: 50px;
  transform: rotate(180deg);
  top: 0;
`

const MarketplacesPage = ({ data }) => {
  const marketplacesItemsData = [
    {
      icon: null,
      iconAlt: null,
      headline: data.contentstackMarketplacesPage.marketplaces_item_1_title,
      description:
        data.contentstackMarketplacesPage.marketplaces_item_1_description,
    },
    {
      icon: null,
      iconAlt: null,
      headline: data.contentstackMarketplacesPage.marketplaces_item_2_title,
      description:
        data.contentstackMarketplacesPage.marketplaces_item_2_description,
    },
    {
      icon: null,
      iconAlt: null,
      headline: data.contentstackMarketplacesPage.marketplaces_item_3_title,
      description:
        data.contentstackMarketplacesPage.marketplaces_item_3_description,
    },
  ]

  return (
    <Layout>
      <SEO
        title={data.contentstackMarketplacesPage.title}
        description={data.contentstackMarketplacesPage.seo_description}
      />

      <ConstrainedWidthContainer>
        <Flex flexWrap="wrap" alignItems="center" pb={[5]} mb={[3, 5]}>
          <Box width={[1, 6 / 12]}>
            <Box mb={[4]}>
              <HeroHeadline>
                {data.contentstackMarketplacesPage.hero_headline}
              </HeroHeadline>
            </Box>

            <Box width={[1, 1, 1, 10 / 12]}>
              <Paragraph>
                {data.contentstackMarketplacesPage.hero_description}
              </Paragraph>
            </Box>
          </Box>

          <HeroImageContainer width={[1, 6 / 12]}>
            <SVGDropper jsonObject={HeroSVG} loop={false} autoplay={true} />
          </HeroImageContainer>
        </Flex>
      </ConstrainedWidthContainer>

      <ColoredBackgroundContainer py={[5]}>
        <CurveBoxTop />
        <ConstrainedWidthContainer>
          <Flex
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box width={[1, 1, 6 / 12]}>
              {marketplacesItemsData.map(item => (
                <Box px={[0, 5]} key={item.headline} mb={[3]}>
                  <ProductSectionItem
                    headline={item.headline}
                    description={item.description}
                    icon={{ url: item.icon, altText: item.iconAlt }}
                    headlineColor="black"
                  />
                </Box>
              ))}
            </Box>

            <VerticalImageContainer width={[1, 6 / 12]} mb={[0, 5]}>
              <SolutionsMarketplacesSVG alt="Marketplaces Graphic" />
            </VerticalImageContainer>

            <VerticalSectionImageMobile
              src={MarketplacesImageMobile}
              alt="Marketplaces Graphic"
            />
          </Flex>
          <ProductsGetInTouch
            text={
              data.contentstackMarketplacesPage.marketplaces_cta_description
            }
          />
        </ConstrainedWidthContainer>
        <CurveBoxBottom />
      </ColoredBackgroundContainer>

      <GetInTouch />
    </Layout>
  )
}

export default MarketplacesPage

export const query = graphql`
  query {
    contentstackMarketplacesPage {
      hero_description
      hero_headline
      title
      seo_description
      marketplaces_cta_description
      marketplaces_item_1_description
      marketplaces_item_1_title
      marketplaces_item_2_description
      marketplaces_item_2_title
      marketplaces_item_3_description
      marketplaces_item_3_title
    }
  }
`
